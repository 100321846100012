import React, { createContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import '../../i18n/i18n';

export const PinMapContext = createContext();

export default function PinMapProvider({ children }) {

  const { i18n } = useTranslation();
  

  const [page, setPage] = useState(() => {
    const path = window.location.pathname.split("/")[1];
    return path || "map";
  });
  
  const [showIntro, setShowIntro] = useState(true);
  const [showInstructions, setShowInstructions] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [showInfoBox, setShowInfoBox] = useState(false);
  const [mapLanguage, setMapLanguage] = useState(i18n.language);

  const [mapHeight, setMapHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState();

  const [pinData, setPinData] = useState([]);
  const [pinAnnotationCategories, setPinAnnotationCategories] = useState([]);
  const [pinFeatureCollection, setPinFeatureCollection] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [newPinData, setNewPinData] = useState(false);

  const [openPin, setOpenPin] = useState(null);
  
  useEffect(() => {
    i18n.changeLanguage(mapLanguage);
    document.body.setAttribute("language", mapLanguage);
  }, [mapLanguage]);


  return (
    <PinMapContext.Provider
      value={{
        page, setPage,
        showIntro, setShowIntro,
        showInstructions, setShowInstructions,
        loading,
        setLoading,
        map, setMap,
        pinData, setPinData,
        pinFeatureCollection, setPinFeatureCollection,
        pinAnnotationCategories, setPinAnnotationCategories,
        newPinData, setNewPinData,
        mapLanguage, setMapLanguage,
        openPin, setOpenPin,
        showInfoBox, setShowInfoBox,
        mapHeight, setMapHeight,
        isMobile, setIsMobile
      }}
    >
      {children}
    </PinMapContext.Provider>
  );
}