import React, { useContext, useEffect, useState } from "react";

import { reactionOptions } from "./utils/dataUtils";

const Reactions = (props) => {
  const { pinData, handleCallback } = props;

  const [reactionsDone, setReactionsDone] = useState([]);

  const clickReaction = (reaction) => {
    let existingReaction = reactionsDone.filter((r) => r.emoji === reaction);
    if (existingReaction.length > 0) {
      deleteReaction(existingReaction[0]);
    } else {
      createReaction(reaction);
    }
  };

  const createReaction = (reaction) => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const formData = new FormData();

    formData.append("pin_reaction[emoji]", reaction);
    formData.append("pin_reaction[pin_id]", pinData.id);

    fetch("/pin_reactions", {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        const newPinData = {
          ...pinData,
          pin_reactions: [...pinData.pin_reactions, data],
        };
        handleCallback(newPinData);

        setReactionsDone((reactionsDone) => [...reactionsDone, data]);
      })
      .catch((error) => {
        console.error("Error posting new pin:", error);
      });
  };

  const deleteReaction = (reaction) => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch("/pin_reactions/" + reaction.id, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        
        const newPinData = {
          ...pinData,
          pin_reactions: pinData.pin_reactions.filter(
            (reaction) => reaction.id !== data.id
          ),
        };
        handleCallback(newPinData);

        setReactionsDone((reactionsDone) =>
          reactionsDone.filter((reaction) => reaction.id !== data.id)
        );
      })
      .catch((error) => {
        console.error("Error posting new pin:", error);
      });
  };

  return (
    <>
      {reactionOptions.map((reaction) => {
        return (
          <div
            key={reaction.emoji}
            id={reaction.emoji}
            className={
              reactionsDone.find((r) => r.emoji === reaction.emoji)
                ? "pin-reaction-option selected"
                : "pin-reaction-option"
            }
            onClick={() => clickReaction(reaction.emoji)}
          >
            <div className="reaction-icon">
              <img
                src={
                  reactionsDone.find((r) => r.emoji === reaction.emoji)
                    ? reaction.iconActive
                    : reaction.icon
                }
              />
            </div>
            <span className="small">
              {
                pinData.pin_reactions.filter(
                  (r) => r.emoji === reaction.emoji
                ).length
              }
            </span>
          </div>
        );
      })}
    </>
  );
};

export default Reactions;
