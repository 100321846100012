import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "../PinMapProvider";

import Reactions from "../Reactions";

const FeaturedPinPage = () => {
  const { t } = useTranslation();

  const { setPage } = useContext(PinMapContext);

  const [featuredPin, setFeaturedPin] = useState(null);
  const [pinHeadline, setPinHeadline] = useState(t("showPin.title"));

  useEffect(() => {
    fetch("/pins/featured_pin")
      .then((response) => response.json())
      .then((data) => {
        setFeaturedPin(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  useEffect(() => {
    if (!featuredPin) return;

    const nameAnnotation = featuredPin.annotation_values.find(
      (a) => a.annotation_category.name === "Give this place a name"
    );

    if (nameAnnotation) {
      setPinHeadline(nameAnnotation.value);
    }
  }, [featuredPin]);

  const handleReactionCallback = (newPinData) => {
    setFeaturedPin(newPinData);
  };

  if (featuredPin === null) return <></>;

  return (
    <div
      id="featured-pin-page"
      className="pin-map-page-container flex-page-container bg-white"
    >
      <div className="featured-pin-image-container relative p-1 w-100">
        <img className="featured-pin-image mt-1" src={featuredPin?.image_url} />
      </div>

      <div className="featured-pin-description-container relative p-1 mb-2 w-100">
        <div id="pin-reaction-container">
          <Reactions
            pinData={featuredPin}
            handleCallback={handleReactionCallback}
          />
        </div>
        
        <h1 className="show-pin-headline mt-1">{pinHeadline}</h1>
        {featuredPin.annotation_values?.map((annotation) => {
          if (annotation.annotation_category.annotation_type === "textarea") {
            return (
              <p
                className="gray-text international-font"
                key={annotation.value}
              >
                {annotation.value}
              </p>
            );
          }
        })}
        <div className="mt-1" id="enter-map-btn-container">
          <button className="btn" onClick={() => setPage("map")}>
            {t("featuredPin.goToTheMap")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPinPage;
