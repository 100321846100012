import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const JoinPage = () => {
  const { t } = useTranslation();

  return (
    <div
      id="join-us-page"
      className="pin-map-page-container flex-page-container bg-white"
    >
      <div className="pin-map-page-content p-1 mt-1">
        <h1 className="show-pin-headline">{t("joinUs.headline")}</h1>
        <p className="gray-text">{t("joinUs.description")}</p>
        <div className="mt-1" id="enter-map-btn-container">
          <a href="" target="_blank" >
            <button className="btn">{t("joinUs.signUpBtnText")}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinPage;
